import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { Component, Injector, OnDestroy, OnInit, Signal, input, output, signal } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  Observable,
  Subscription,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  fromEvent,
  map,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HeatmapTooltipComponent } from '@c/stock-heatmap/heatmap-tooltip/heatmap-tooltip.component';
import { DialogsService } from '@s/common/dialogs.service';
import { HistoricalDataService } from '@s/historical-data.service';
import { NavigationService } from '@s/navigation.service';
import { ObservableService } from '@s/observable.service';
import { SecurityDataDetailsService } from '@s/security-data-details.service';
import { StockHeatmapService } from '@s/stock-heatmap.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { ExchangeCountriesCodes, MobileWidth, TabNames } from '@const';
import { formatMarketCap } from '@c/shared/symbol-details-panel/symbol-details-panel.utils';
import { heatmapDetailsTooltipPositoons } from './sector-indusrty-heatmap-details.utils';
import { IPanelSymbolDetails } from '@c/shared/symbol-details-panel/symbol-details-panel.model';
import { D3ChartNode, HeatmapElement, TooltipSymbol } from '@c/stock-heatmap/stock-heatmap.model';
import {
  IHeatmapPanelSettings,
  IHeatmapTooltipData,
  ISelectedHeatmapElements,
} from './sector-industry-heatmap-details.models';

@Component({
  selector: 'app-sector-industry-heatmap-details',
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule],
  templateUrl: './sector-industry-heatmap-details.component.html',
  styleUrl: './sector-industry-heatmap-details.component.scss',
})
export class SectorIndustryHeatmapDetailsComponent implements OnInit, OnDestroy {
  protected securityId = input.required<number>();
  protected symbolSelected = output<ISymbol>();

  protected securityDetails: Signal<IPanelSymbolDetails>;
  protected isMobile = false;
  protected isSectorLink = signal(false);
  protected isDataLoading = signal(false);

  private tooltipState = signal<IHeatmapTooltipData>(null);
  private updateChartData = signal<IHeatmapTooltipData>(null);
  private overlayRef: OverlayRef;
  private mobileWidth = MobileWidth;
  private selectedHeatmapElements: ISelectedHeatmapElements = null;
  private subscription: Subscription = new Subscription();

  constructor(
    private securityDataDetailsService: SecurityDataDetailsService,
    private symbolsService: SymbolsService,
    private historicalDataService: HistoricalDataService,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private stockHeatmapService: StockHeatmapService,
    private observableService: ObservableService,
    private dialogsService: DialogsService,
    private navigationService: NavigationService,
    private overlay: Overlay,
    private injector: Injector
  ) {}

  async ngOnInit(): Promise<void> {
    this.securityDetails = toSignal(
      toObservable(this.securityId, { injector: this.injector }).pipe(switchMap((id) => this.getSecurityDetails(id))),
      { injector: this.injector }
    );

    this.subscription.add(
      toObservable(this.updateChartData, { injector: this.injector })
        .pipe(
          filter((value) => !!value),
          tap(() => {
            this.selectedHeatmapElements = null;
            this.isSectorLink.set(false);
            this.stockHeatmapService.unsubscribeFromChartData();
          }),
          switchMap((tooltipData) =>
            this.stockHeatmapService.heatmapDataForDataWindowTooltip$.pipe(
              map((chartData) => ({ tooltipData, chartData })),
              take(1),
            )
          ),
        )
        .subscribe(({ tooltipData, chartData }) => {
          if (!this.tooltipState().needShow) {
            return;
          }

          this.selectedHeatmapElements = {
            hoveredTicker: tooltipData.securityDetails,
            sector: this.findSectorNode(chartData, tooltipData.securityDetails),
          };

          if (this.selectedHeatmapElements.sector) {
            this.selectedHeatmapElements = {
              ...this.selectedHeatmapElements,
              industry: this.findIndustryNode(this.selectedHeatmapElements.sector, tooltipData.securityDetails),
            };
          }

          if (this.selectedHeatmapElements.industry) {
            this.selectedHeatmapElements = {
              ...this.selectedHeatmapElements,
              ticker: this.findTickerNode(this.selectedHeatmapElements.industry, tooltipData.securityDetails),
            };
          }

          this.isDataLoading.set(false);

          if (this.isMobile) {
            this.showHeatmapDetailPopup(this.selectedHeatmapElements);
          } else {
            this.showHeatmapDetailTooltip(this.selectedHeatmapElements, tooltipData.element);
          }

          this.isSectorLink.set(!!this.selectedHeatmapElements.industry);
        })
    );

    this.subscription.add(
      toObservable(this.tooltipState, { injector: this.injector })
        .pipe(
          debounceTime(200),
          filter((data) => !!data),
          distinctUntilChanged((prev, curr) => prev.needShow === curr.needShow),
          tap((data) => {
            if (data.needShow) {
              this.updateChartData.update(() => data);
            }
          })
        )
        .subscribe((data: IHeatmapTooltipData) =>
          data.needShow
            ? this.isDataLoading.set(data.needShow)
            : this.hideTooltip()
        )
    );

    this.subscription.add(this.getIsMobile().subscribe((mobile) => (this.isMobile = mobile)));
  }

  protected emitHideAll(): void {
    this.tooltipState.update(() => ({
      needShow: false,
      securityDetails: this.securityDetails(),
    }));
  }

  protected onSectorLinkClick(element: HTMLElement): void {
    this.tooltipState.update(() => ({
      needShow: true,
      element,
      securityDetails: this.securityDetails(),
    }));
  }

  private async showHeatmapDetailPopup(selectedHeatmapElements: ISelectedHeatmapElements): Promise<void> {
    this.overlayRef?.dispose();
    this.dialogsService.closeAll();

    const modalCloseResult = await this.dialogsService.openHeatmapDetailsModal(
      selectedHeatmapElements,
      this.symbolsCompareFn(selectedHeatmapElements)
    );

    if (modalCloseResult) {
      if (modalCloseResult.isGoToHeatmap) {
        this.goToHeatmap();
      }

      if (modalCloseResult.newSymbol) {
        this.updateSymbol(modalCloseResult.newSymbol);
      }
    }

    this.emitHideAll();
  }

  private goToHeatmap(): void {
    if (!this.isSectorLink()) {
      return;
    }

    const settings: IHeatmapPanelSettings = {
      filters: {
        sector: {
          id: this.selectedHeatmapElements.sector?.id,
          title: this.selectedHeatmapElements.sector?.name,
        },
        industry: {
          id: this.selectedHeatmapElements.industry?.id,
          title: this.selectedHeatmapElements.industry?.name,
        },
      },
      index: 'CompositeIndex',
      marketTime: 'isMarket',
    };

    this.hideTooltip();
    this.observableService.tradingPanelOrderInput.next(null);
    this.navigationService.redirectToTab(TabNames.Heatmap, settings);
  }

  private showHeatmapDetailTooltip(selectedHeatmapElements: ISelectedHeatmapElements, element: HTMLElement): void {
    this.overlayRef?.dispose();
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(element)
      .withPositions(heatmapDetailsTooltipPositoons);
    this.overlayRef = this.overlay.create({ positionStrategy, hasBackdrop: true, backdropClass: 'heatmap-details-backdrop' });

    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const heatmapTooltipRef = this.overlayRef.attach(new ComponentPortal(HeatmapTooltipComponent));
      heatmapTooltipRef.instance.heatmapVisibilityConfig$.next({
        mode: 'Extended',
      });
      heatmapTooltipRef.instance.selectedSymbol = selectedHeatmapElements.ticker?.symbol;
      heatmapTooltipRef.instance.price = selectedHeatmapElements.ticker?.price;
      heatmapTooltipRef.instance.sector = selectedHeatmapElements.sector?.name;
      heatmapTooltipRef.instance.industryNode = selectedHeatmapElements.industry;
      heatmapTooltipRef.instance.tickerNode = selectedHeatmapElements.ticker;
      heatmapTooltipRef.instance.hoveredTicker = selectedHeatmapElements.hoveredTicker;
      heatmapTooltipRef.instance.compareFunction = this.symbolsCompareFn(selectedHeatmapElements);

      this.subscription.add(
        this.overlayRef.outsidePointerEvents().subscribe(() =>
          this.emitHideAll()
        )
      );

      this.subscription.add(
        heatmapTooltipRef.instance.onClose.subscribe((closeOutput) => {
          if (closeOutput && closeOutput.isGoToHeatmap) {
            this.goToHeatmap();
          }

          if (closeOutput && closeOutput.newSymbol) {
            this.updateSymbol(closeOutput.newSymbol);
          }

          this.emitHideAll();
        })
      );
    }
  }

  private getIsMobile(): Observable<boolean> {
    return fromEvent(window, 'resize').pipe(
      startWith(window.innerWidth < this.mobileWidth),
      debounceTime(500),
      map(() => window.innerWidth < this.mobileWidth),
      distinctUntilChanged()
    );
  }

  private hideTooltip(): void {
    this.overlayRef?.detach();
    this.isSectorLink.set(false);
    this.isDataLoading.set(false);
  }

  private findSectorNode(
    chartData: D3ChartNode<D3ChartNode<HeatmapElement>>[],
    securityDetails: IPanelSymbolDetails
  ): D3ChartNode<D3ChartNode<HeatmapElement>> | null {
    return [...chartData].find((data) => data.name.toLowerCase() === securityDetails.sector.toLowerCase()) || null;
  }

  private findIndustryNode(
    sectorNode: D3ChartNode<D3ChartNode<HeatmapElement>>,
    securityDetails: IPanelSymbolDetails
  ): D3ChartNode<HeatmapElement> | null {
    const normalizeString = (str: string) => str.replace(/\s/g, '').toLowerCase();
    const normalizedIndustry = normalizeString(securityDetails.industry);

    return (
      ({ ...sectorNode, children: [...sectorNode.children] }.children.find(
        (item) => normalizeString(item.name) === normalizedIndustry
      ) as D3ChartNode<HeatmapElement>) || null
    );
  }

  private findTickerNode(industryNode: D3ChartNode<HeatmapElement>, securityDetails: IPanelSymbolDetails): HeatmapElement | null {
    return (
      ({ ...industryNode, children: [...industryNode.children] }.children.find(
        (item) => item.id === securityDetails.symbol
      ) as HeatmapElement) || null
    );
  }

  private getSecurityDetails(id: number): Observable<IPanelSymbolDetails> {
    return combineLatest([
      from(this.symbolsService.getById(id)),
      from(this.securityDataDetailsService.get(id)),
      from(this.historicalDataService.get(id)),
    ]).pipe(
      filter(([symbol, security, historicalData]) => symbol && security && historicalData),
      map(([symbol, security, historicalData]) => {
        const lastTradePrice = historicalData.length ? historicalData[historicalData.length - 1].close : null;

        return {
          company: symbol.description,
          createdDate: symbol.last_updated.toString(),
          exchange: symbol.exchange_name,
          exchangeCode: symbol.exchange_code,
          countryCode: symbol.country_code,
          industry: security.industry_name,
          lastTradePrice,
          longDescription: security.long_description,
          longDescriptionFormatted: security.long_description,
          sector: security.sector_name,
          securityId: security.security_id,
          symbol: security.symbol,
          marketCap: security.market_cap ?? null,
          formattedMarketCap: formatMarketCap(security.market_cap),
          priceEarningsRatio: security.pe_ratio ?? null,
          dividendYield: security.dividend_yield ?? null,
          eps: security.earnings_share ?? null,
          percentInsiders: security.percent_insiders ?? null,
          percentInstitutions: security.percent_institutions ?? null,
          isFromCache: false,
          symbolIndexDetails: { isVisible: false, displayValue: '' },
        };
      })
    );
  }

  private symbolsCompareFn(selectedHeatmapElements: ISelectedHeatmapElements): (arr: TooltipSymbol[]) => TooltipSymbol[] {
    return (arr: TooltipSymbol[]): TooltipSymbol[] => {
      if (selectedHeatmapElements.ticker?.symbol) {
        return [arr.shift(), ...arr.sort((a, b) => (b.diff || 0) - (a.diff || 0))];
      }
      return [...arr.sort((a, b) => (b.diff || 0) - (a.diff || 0))];
    };
  }

  private async updateSymbol(newSymbol: TooltipSymbol): Promise<void> {
    const symbol = await this.symbolsService.getBySymbol(newSymbol.symbol, ExchangeCountriesCodes.US);

    if (symbol) {
      this.symbolSelected.emit(symbol);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
