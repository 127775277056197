import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Features, pluginNames, TabNames, UserAccessLevels } from '@const';
import { IFeaturesDetails } from '@mod/editions/editions.model';
import { ObservableService } from '@s/observable.service';

const DEMO_PAGE_ROOT_URL = 'editions/demo/';

@Injectable({
  providedIn: 'root',
})
export class EditionsService {
  constructor(
    private router: Router,
    private observableService: ObservableService,
  ) {}

  // important: do not use this config as information about tabs, here for each feature can be set any existing tab (page)
  // similar config/settings are also existing in back-end side
  // for page and demoPage: use path from root - "editions/demo/ ... "
  // tabName: to set active tab before redirect to feature page
  public static featuresDetails: Record<Features, IFeaturesDetails> = {
    [Features.PowerX]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'powerx',
      pageURL: 'powerx',
      accessLevels: [UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.PowerX,
    },
    [Features.Wheel]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'wheel',
      pageURL: 'wheel',
      accessLevels: [UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.Wheel,
    },
    [Features.HolidaysCalendar]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'holidays-calendar',
      pageURL: 'calendars/holidays',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.HolidaysCalendar,
    },
    [Features.EconomicCalendar]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'economic-calendar',
      pageURL: 'calendars/economic',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.EconomicCalendar,
    },
    [Features.MMTrades]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'mm-trades',
      pageURL: 'mm-trades',
      accessLevels: [UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.MMTrades,
    },
    [Features.IBot]: {
      // just hidden element for now, no demo-page for this feature
      demoPageURL: '',
      pageURL: null,
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: null,
    },
    [Features.BullCallSpread]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'bull-call-spread',
      pageURL: 'bull-call-spread',
      accessLevels: [UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.BullCallSpread,
    },
    [Features.TradingHub]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'trading-hub',
      pageURL: null,
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: null,
    },
    [Features.TradingLog]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'trading-log',
      pageURL: 'trading-log',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.TradingLog,
    },
    [Features.ExpectedMove]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'expected-move',
      pageURL: null,
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: null,
    },
    [Features.WheelCalculatorLiveData]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'wheel-calculator-live-data',
      pageURL: 'wheel',
      accessLevels: [UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.Wheel,
    },
    [Features.TradingPanel]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'trading-panel',
      pageURL: 'trading-panel',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.TradingPanel,
    },
    [Features.StockScreeners]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'stock-screeners',
      pageURL: 'stock-screeners',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.StockScreeners,
    },
    [Features.ImportTrades]: {
      demoPageURL: '',
      pageURL: 'import-trades',
      accessLevels: [UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.ImportTrades,
    },
    [Features.EarningsAnalysis]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'earnings-analysis',
      pageURL: 'earnings-analysis',
      accessLevels: [UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.EarningsAnalysis,
    },
    [Features.EarningsCalendar]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'earnings-calendar',
      pageURL: 'calendars/earnings',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.EarningsCalendar,
    },
    [Features.DividendsCalendar]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'dividends-calendar',
      pageURL: 'calendars/dividends',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.DividendsCalendar,
    },
    [Features.Heatmap]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'heatmap',
      pageURL: 'heatmap',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.Heatmap,
    },
    [Features.AssetCorrelation]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'asset-correlation',
      pageURL: 'asset-correlation',
      accessLevels: [UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.AssetCorrelation,
    },
    [Features.Disclaimer]: {
      demoPageURL: '',
      pageURL: 'disclaimer',
      accessLevels: [UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.Disclaimer,
    },
    [Features.PxoCommunity]: {
      // just hidden element for now, no demo-page for this feature
      demoPageURL: '',
      pageURL: '',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: null,
    },
    [Features.LowHighIndicator]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'low-high-indicator',
      pageURL: null,
      accessLevels: [UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: null,
    },
    [Features.SmileyStatistics]: {
      // just hidden element for now, no demo-page for this feature
      demoPageURL: '',
      pageURL: '',
      accessLevels: [UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: null,
    },
    [Features.Wtf]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'wtf',
      pageURL: 'wtf',
      accessLevels: [UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: pluginNames.Wtf,
      tabName: TabNames.Wtf,
    },
    [Features.ShortSellingStocks]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'shorting-stocks',
      pageURL: 'shorting-stocks',
      accessLevels: [UserAccessLevels.Mastermind],
      pluginName: pluginNames.ShortingStocks,
      tabName: TabNames.ShortSellingStocks,
    },
    [Features.ShortingStocksScanner]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'shorting-stocks-scanner',
      pageURL: 'shorting-stocks-scanner',
      accessLevels: [UserAccessLevels.Mastermind],
      pluginName: pluginNames.ShortingStocks,
      tabName: TabNames.ShortingScanner,
    },
    [Features.VideoHub]: {
      demoPageURL: '',
      pageURL: 'video-hub',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      tabName: TabNames.VideoHub,
      pluginName: null,
    },
    [Features.MasterCalendar]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'master-calendar',
      pageURL: 'calendars/master',
      accessLevels: [UserAccessLevels.Basic, UserAccessLevels.Silver, UserAccessLevels.Max, UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.MasterCalendar,
    },
    [Features.DividendsStrategy]: {
      demoPageURL: DEMO_PAGE_ROOT_URL + 'dividends-strategy',
      pageURL: 'dividends-strategy',
      accessLevels: [UserAccessLevels.Mastermind],
      pluginName: null,
      tabName: TabNames.DividendsStrategy,
    },
  };

  public getUserAccessLevel(): UserAccessLevels {
    return this.observableService.accessLevel.getValue();
  }

  public getAvailablePlugins(): string[] {
    return this.observableService.availablePlugins.getValue();
  }

  public isFeatureAvailable(
    feature: Features | null,
    featuresDetails: Record<Features, IFeaturesDetails> = EditionsService.featuresDetails,
  ): boolean {
    if (!feature) {
      return true;
    }

    const accessLevel = this.getUserAccessLevel();
    const availablePlugins = this.getAvailablePlugins();

    return featuresDetails[feature]?.accessLevels.includes(accessLevel)
      || availablePlugins.includes(featuresDetails[feature]?.pluginName);
  }

  public async redirectToDemoPage(
    feature: Features,
    featuresDetails: Record<Features, IFeaturesDetails> = EditionsService.featuresDetails,
  ): Promise<void> {
    await this.router.navigate([featuresDetails[feature].demoPageURL], {
      replaceUrl: true,
    });
  }
}
