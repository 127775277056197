import { CdkDrag, CdkDragEnd, CdkDragHandle } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild, input, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SanitizeHtmlPipe } from '@c/trading-hub/pipes/sanitize-html.pipe';
import { VimeoPlayerComponent } from '@c/video-hub/vimeo-player/vimeo-player.component';
import { YoutubePlayerComponent } from '@c/video-hub/youtube-player/youtube-player.component';
import { VideoHubTabNames } from '@constants/video-hub.contants';
import { BackgroundVideoResizableDirective } from '@core/directives/background-video-resize/background-video-resizable.directive';
import { ElementResizeOptions } from '@mod/video-hub/video-hub-resize-options.model';
import { LocalStorageService } from '@s/local-storage.service';
import { NavigationService } from '@s/navigation.service';
import { ObservableService } from '@s/observable.service';
import { VideoHubNavigationService } from '@s/video-hub/video-hub-navigation.service';
import { VideoHubVideoPositionService } from '@s/video-hub/video-hub-video-position.service';
import { VideoHubService } from '@s/video-hub/video-hub.service';
import { VideoHubPlayer } from '@t/video-hub/video-hub-player.interface';
import { isIntegerNumber } from '@u/utils';
import { Subscription, interval } from 'rxjs';
import { BackgroundVideoModalModel } from './background-video-modal.model';

@Component({
  selector: 'app-background-video-modal',
  standalone: true,
  imports: [
    YoutubePlayerComponent,
    VimeoPlayerComponent,
    MatIconModule,
    CdkDrag,
    CdkDragHandle,
    MatButtonModule,
    BackgroundVideoResizableDirective,
    MatProgressSpinnerModule,
    MatTooltipModule,
    SanitizeHtmlPipe,
  ],
  templateUrl: './background-video-modal.component.html',
  styleUrl: './background-video-modal.component.scss',
})
export class BackgroundVideoModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHandler() {
    await this.sendVideoStopPosition();
    return await this.handleWindowUnload();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleWindowResize();
  }

  @ViewChild('player')
  public videoPlayer: VideoHubPlayer;
  @ViewChild('drag')
  public drag: CdkDrag;
  protected videoMetadata = input.required<BackgroundVideoModalModel>();
  protected isMaskVisible = signal<boolean>(false);
  protected currentPositionAndSizeData = signal<{
    x: number;
    y: number;
    width: number;
    height: number;
  }>(null);
  protected elementResizeOptions: ElementResizeOptions = {
    aspectRatio: 16 / 9,
    maxHeight: Infinity,
    minHeight: 230,
    parentClass: '.background-video-pane',
  };

  private subscription: Subscription = new Subscription();

  constructor(
    private videoHubService: VideoHubService,
    private navigationService: NavigationService,
    private localStorageService: LocalStorageService,
    private observableService: ObservableService,
    private videoHubVideoPositionService: VideoHubVideoPositionService,
    private videoHubNavigationService: VideoHubNavigationService,
  ) {}

  ngOnInit(): void {
    this.currentPositionAndSizeData.set({
      x: this.videoMetadata()?.position?.x ?? 50,
      y: this.videoMetadata()?.position?.y ?? window.innerHeight - this.videoHubService.BackgroundVideoInitialHeight - 100,
      width: this.videoMetadata()?.size?.width ?? 230 * (16 / 9),
      height: this.videoMetadata()?.size?.height ?? 230,
    });

    this.subscription.add(
      this.observableService.onUserLogout.subscribe(() => {
        this.videoHubService.closeBackgroundVideo();
      }),
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      interval(this.videoHubVideoPositionService.sendUpdateUserStopTimeSeconds * 1000).subscribe(() => this.sendVideoStopPosition()),
    );
  }

  public async close(): Promise<void> {
    await this.sendVideoStopPosition();
    this.videoHubService.closeBackgroundVideo();
  }

  protected onResizeStart() {
    this.isMaskVisible.set(true);
    (document.querySelector('.background-video-backdrop') as HTMLElement).style.display = 'block';
  }

  protected onResizeEnd() {
    this.isMaskVisible.set(false);
    (document.querySelector('.background-video-backdrop') as HTMLElement).style.display = 'none';
  }

  public onResizing(data: { x: number; y: number; width: number; height: number }) {
    this.currentPositionAndSizeData.set({
      ...data,
    });
  }

  public async expandVideo(): Promise<void> {
    await this.sendVideoStopPosition();
    await this.videoHubNavigationService.redirectToVideoHubTab(
      VideoHubTabNames.VideoHubPlayer,
      [this.videoMetadata().video.id.toString()],
      { t: await this.getCurrentPlayerTime() },
    );
  }

  protected onDragStarted(): void {
    this.isMaskVisible.set(true);
    (document.querySelector('.background-video-backdrop') as HTMLElement).style.display = 'block';
  }

  protected onDragEnded(event: CdkDragEnd): void {
    const rect = event.source.element.nativeElement.getBoundingClientRect();
    this.currentPositionAndSizeData.update((data) => ({
      ...data,
      x: rect.x,
      y: rect.y,
    }));
    this.isMaskVisible.set(false);
    (document.querySelector('.background-video-backdrop') as HTMLElement).style.display = 'none';
  }

  protected async openInSeparateWindow(): Promise<void> {
    this.pauseVideo();
    await this.sendVideoStopPosition();
    const videoUrl = await this.videoPlayer.getVideoUrl();
    window.open(videoUrl, '_blank');
  }

  protected async getCurrentPlayerTime(): Promise<number> {
    return this.videoPlayer.getCurrentTime();
  }

  private pauseVideo(): void {
    this.videoPlayer.pause();
    this.sendVideoStopPosition();
  }

  private handleWindowResize(): void {
    if (window.innerWidth < 991) {
      this.close();
    }
  }

  private async handleWindowUnload(): Promise<boolean> {
    this.localStorageService.set(
      this.videoHubService.BackgroundVideoLocalStorageKey,
      JSON.stringify({
        video: { ...this.videoMetadata().video },
        timestamp: await this.videoPlayer.getCurrentTime(),
        size: { width: this.currentPositionAndSizeData().width, height: this.currentPositionAndSizeData().height },
        position: { x: this.currentPositionAndSizeData().x, y: this.currentPositionAndSizeData().y },
      }),
    );

    return true;
  }

  private async sendVideoStopPosition(seconds?: number): Promise<void> {
    const object = {
      videoId: this.videoMetadata().video.videoId,
      seconds: isIntegerNumber(seconds) ? seconds : await this.videoPlayer.getCurrentTime(),
    };
    return await this.videoHubVideoPositionService.updateVideoStopPositions(object);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
