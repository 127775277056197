<div class="extended-data-window" [class.extended-data-without-icon]="!showHeatmapDetailsDataWindow">
  <div class="header-section">
    <div class="warning-message" *ngIf="!!warningMessage">
      {{ warningMessage }}
    </div>
    <div class="header-container">
      <div class="header-title">
        <div class="first-line">
          <app-symbol-flag [flag]="currentFlag.flag" [securityId]="(securityDetails$ | async)?.securityId"
            [smileyListType]="smileyListType" [sendUpdateSignalOnChange]="true" [showStatisticsWhenVisible]="true"
            [keepSubscriptionAndShowVotesHint]="true" (menuClosed)="onChangeSymbolFlag($event)"
            class="symbol-flag"></app-symbol-flag>

          <div *ngIf="(securityDetails$ | async) as securityDetails" class="company-data">
            <!--  reserve space for 4 digit fraction, hide 0 in 3 and 4 positions  -->
            <span id="last_trade_price">
              <span *ngIf="lastTradePriceFormatted.value !== 0">$</span>
              {{ lastTradePriceFormatted.visiblePart }}
              <span class="transparent">{{ lastTradePriceFormatted.hiddenFraction }}</span>
            </span>

            <span class="rr-font">
              <span *ngIf="securityDetails">•&nbsp;&nbsp;</span>
              <span [class.underlined]="securityDetails.longDescription !== null"
                [matTooltip]="securityDetails.longDescriptionFormatted"
                [matTooltipDisabled]="!securityDetails.longDescription" [matTooltipPosition]="'below'"
                (contextmenu)="$event.preventDefault()">
                <span>{{ securityDetails.symbol | uppercase }}</span>
                <span>-</span>
                <span>{{ securityDetails.company }}</span>
              </span>
            </span>

            @if (showHeatmapDetailsDataWindow && isHeatmapFeatureAvailable) {
            <app-sector-industry-heatmap-details [securityId]="securityDetails.securityId"
              (symbolSelected)="onSymbolSelected($event)"></app-sector-industry-heatmap-details>
            } @else {
            <ng-container [ngTemplateOutlet]="sectorIndustryPlaceholder"
              [ngTemplateOutletContext]="{ securityDetails }"></ng-container>
            }
          </div>

          <div class="actions-container" *ngIf="(securityDetails$ | async) as securityDetails">
            <button mat-icon-button color="primary" class="icon-button"
              (click)="setEarningsAnalysisSymbol(securityDetails.securityId)" [matTooltip]="'Analyze earnings'"
              [matTooltipHideDelay]="0">
              <mat-icon [svgIcon]="'analyze-earning-icon'" class="analyze-earning-icon middle-icon"></mat-icon>
            </button>

            <button mat-icon-button color="primary" class="icon-button"
              (click)="redirectToAssetCorrelationPage(securityDetails)" [matTooltipShowDelay]="0"
              [matTooltipHideDelay]="0" [matTooltip]="'Compare assets correlation of this symbol with others'">
              <mat-icon [svgIcon]="'correlation'" class="news-icon middle-icon"></mat-icon>
            </button>

            <button mat-icon-button color="primary" *ngIf="currentElementsVisibilityConfig.showOpenNewsBtn"
              (click)="openNews(securityDetails)" class="icon-button" [matTooltip]="'Check fundamentals'"
              [matTooltipPosition]="'left'" [matTooltipHideDelay]="0">
              <mat-icon [svgIcon]="'news-icon'" class="news-icon middle-icon"></mat-icon>
            </button>

            <div class="watchlist-button-container bright-border" (click)="onChangeWatchlist()">
              <button mat-icon-button class="star-button" *ngIf="isInWatchlist" [matTooltipShowDelay]="0"
                [matTooltipHideDelay]="0" [matTooltip]="'Delete from watchlist'">
                <mat-icon [svgIcon]="'star-icon'" class="add-to-list-icon star-icon middle-icon"></mat-icon>
              </button>

              <button mat-icon-button class="star-button" *ngIf="!isInWatchlist" [matTooltipShowDelay]="0"
                [matTooltipHideDelay]="0" [matTooltip]="'Add to watchlist'">
                <mat-icon [svgIcon]="'star-icon'" class="star-icon middle-icon"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  TODO: replace with "@let" after upgrade angular to v18 to avoid nested "@if"  -->
    @if ((securityDetails$ | async); as securityDetails) {
    @if (securityDetails.symbolIndexDetails.isVisible) {
    <div class="indices-wrapper">
      <span>{{ securityDetails.symbolIndexDetails.displayValue }}</span>
    </div>
    }
    }

    <div *ngIf="relativelyToMarketTime !== null" class="relative-to-market-time-section"
      [ngClass]="relativelyToMarketTimeLabels[relativelyToMarketTime].cssClass">
      <mat-icon *ngIf="relativelyToMarketTimeLabels[relativelyToMarketTime].icon !== null"
        [svgIcon]="relativelyToMarketTimeLabels[relativelyToMarketTime].icon" class="pre-post-market-icon"></mat-icon>
      <p class="market-time-label">{{ relativelyToMarketTimeLabels[relativelyToMarketTime].label }}</p>
      <p *ngIf="isThereAnyTrades !== null
          && !isThereAnyTrades
          && relativelyToMarketTimeLabels[relativelyToMarketTime].showTradesState">
        &#x2022; No trades
      </p>
    </div>
  </div>

  <div class="second-row" *ngIf="(securityDetails$ | async) as securityDetails">
    <div *ngIf="currentElementsVisibilityConfig.showEps" class="section">
      <div class="value">
        <ng-container *ngIf="securityDetails.eps !== 0 && securityDetails.eps !== null">
          {{ securityDetails.eps }}
        </ng-container>
        <ng-container *ngIf="securityDetails.eps === 0">0</ng-container>
        <ng-container *ngIf="securityDetails.eps === null">-</ng-container>
      </div>
      <div class="label">
        EPS
      </div>
    </div>

    <div *ngIf="currentElementsVisibilityConfig.showMarketCap" class="section">
      <div class="value">
        <!-- "formatMarketCap" function returns null if raw "marketCap" value is equal to 0 -->
        <ng-container *ngIf="securityDetails.formattedMarketCap !== null">
          {{ securityDetails.formattedMarketCap }}
        </ng-container>
        <ng-container *ngIf="securityDetails.formattedMarketCap === null">-</ng-container>
      </div>
      <div class="label">
        Market Cap
      </div>
    </div>

    <div *ngIf="currentElementsVisibilityConfig.showDividendYield" class="section">
      <div class="value">
        <ng-container *ngIf="securityDetails.dividendYield !== null && securityDetails.dividendYield > 0">
          {{ securityDetails.dividendYield | number: '1.2-2' }}%
        </ng-container>
        <ng-container
          *ngIf="securityDetails.dividendYield === null || securityDetails.dividendYield <= 0">-</ng-container>
      </div>
      <div class="label">
        DIV Yield
      </div>
    </div>

    <div *ngIf="currentElementsVisibilityConfig.showDivPerShare" class="section">
      <div class="value">
        <ng-container *ngIf="divPerShare !== null && divPerShare > 0">
          ${{ divPerShare | number: '1.2-3' }}
        </ng-container>
        <ng-container *ngIf="divPerShare === null || divPerShare <= 0">-</ng-container>
      </div>
      <div class="label">
        DIV Per Share
      </div>
    </div>

    <div *ngIf="currentElementsVisibilityConfig.showUpcomingDividends" class="section">
      <div class="value">
        <ng-container *ngIf="upcomingDividendDate !== null">
          <div class="value upcoming-dividend-container">
            <p class="date">
              {{ upcomingDividendDateShort }}
            </p>
            <div class="in-days" *ngIf="upcomingDividendInDays !== null && upcomingDividendInDays >= 0"
              [matTooltip]="upcomingDividendDate" [matTooltipPosition]="'left'" [matTooltipHideDelay]="0">
              {{ upcomingDividendInDays }} d
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="upcomingDividendDate === null">-</ng-container>
      </div>
      <div class="label">
        Upcoming Dividend
      </div>
    </div>

    <div *ngIf="currentElementsVisibilityConfig.showPriceEarningsRatio" class="section">
      <div class="value">
        <ng-container *ngIf="securityDetails.priceEarningsRatio !== 0 && securityDetails.priceEarningsRatio !== null">
          {{ securityDetails.priceEarningsRatio | number: '1.2-2' }}
        </ng-container>
        <ng-container *ngIf="securityDetails.priceEarningsRatio === 0">0</ng-container>
        <ng-container *ngIf="securityDetails.priceEarningsRatio === null">-</ng-container>
      </div>
      <div class="label">
        P/E
      </div>
    </div>

    <div *ngIf="currentElementsVisibilityConfig.showSharesHoldings" class="section"
      [matTooltip]="'Held by Insiders and Institutions'" [matTooltipPosition]="'left'" [matTooltipHideDelay]="0">
      <div class="value">
        @if ((securityDetails.percentInsiders !== 0 && securityDetails.percentInsiders !== null)
        || (securityDetails.percentInstitutions !== 0 && securityDetails.percentInstitutions !== null)
        ) {
        @if (securityDetails.percentInsiders !== 0 && securityDetails.percentInsiders !== null) {
        {{ securityDetails.percentInsiders | number: '1.0-2' }}%
        } @else {
        -
        }

        <div class="separator">/</div>

        @if (securityDetails.percentInstitutions !== 0 && securityDetails.percentInstitutions !== null) {
        {{ securityDetails.percentInstitutions | number: '1.0-2' }}%
        } @else {
        -
        }
        } @else {
        -
        }
      </div>
      <div class="label">
        <span class="underlined-text">Hel</span>d By
      </div>
    </div>

    <div *ngIf="currentElementsVisibilityConfig.showUpcomingEarnings" class="section">
      <div *ngIf="nextEarningDate" class="value earning-container-extended">
        <p class="earning-report-date">
          {{ earningReportDateShort }}
        </p>
        <div class="total-earning-day" [ngClass]="{ 'red': totalEarningDay >= 0 && totalEarningDay <= 7 }"
          *ngIf="totalEarningDay !== null && totalEarningDay >= 0" [matTooltip]="earningReportDate"
          [matTooltipPosition]="'left'" [matTooltipHideDelay]="0">
          {{ totalEarningDay }} d
        </div>
        <div>
          <app-label *ngIf="isEarningsConfirmed"
            [text]="relativelyToMarketTimeEarningsLabels[earningRelativelyToMarketTime]"
            [iconName]="isEarningsConfirmed ? 'success-icon' : null"
            [appendClass]="isEarningsConfirmed ? 'success' : 'danger'"></app-label>
        </div>
      </div>
      <div *ngIf="!nextEarningDate" class="value">-</div>
      <div class="label">
        Upcoming Earnings
      </div>
    </div>

    @if (showRockyDataWindow) {
    <div class="rocky-icon-wrapper">
      <app-ask-rocky-chat-button (click)="openTradingHubModal(securityDetails)"></app-ask-rocky-chat-button>
    </div>
    }
  </div>

  <ng-content></ng-content>
</div>

<ng-template #sectorIndustryPlaceholder let-securityDetails="securityDetails">
  <div class="sector-indu" *ngIf="securityDetails">
    <span *ngIf="securityDetails.sector" class="sector-value">
      {{ securityDetails.sector }}
    </span>
    <span *ngIf="securityDetails.sector && securityDetails.industry" class="industry-value">
      {{ securityDetails.sector && securityDetails.industry ? '-' : '' }}
    </span>
    <span *ngIf="securityDetails.industry" class="industry-value">
      {{ securityDetails.industry }}
    </span>
  </div>
</ng-template>

<ng-template #emptyValue>
  -
</ng-template>